import { AssetListItem } from './types';
import { DynamicListDefinition } from '@borda/cat-core';

export const initialColumnDynamicData: DynamicListDefinition<AssetListItem> = {
  columns: [
    {
      column: 'assetStatus',
      visible: true,
      width: 50
    },
    {
      column: 'code',
      visible: true,
      width: 150
    },
    {
      column: 'serialNo',
      visible: false,
      width: 150
    },
    {
      column: 'branchName',
      visible: true,
      width: 150
    },
    {
      column: 'brandName',
      visible: true,
      width: 150
    },
    {
      column: 'categoryName',
      visible: true,
      width: 150
    },
    {
      column: 'mainCategoryName',
      visible: false,
      width: 150
    },
    {
      column: 'passiveTag',
      visible: false,
      width: 150
    },
    {
      column: 'modelName',
      visible: true,
      width: 150
    },
    {
      column: 'locationName',
      visible: true,
      width: 200
    },
    {
      column: 'createdByUser',
      visible: false,
      width: 150
    },
    {
      column: 'createdDate',
      visible: false,
      width: 150
    },
    {
      column: 'purchasedDate',
      visible: false,
      width: 150
    },
    {
      column: 'productionDate',
      visible: false,
      width: 150
    },
    {
      column: 'purchasedFirm',
      visible: false,
      width: 150
    },
    {
      column: 'lastSeen',
      visible: true,
      width: 150
    },
    {
      column: 'lastSeenLocation',
      visible: true,
      width: 150
    },
    {
      column: 'custodyUser',
      visible: false,
      width: 150
    }
  ],
  sorts: [{ column: 'categoryName', direction: 'asc' }]
};
