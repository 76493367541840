import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps
} from 'overlayscrollbars-react';
import { forwardRef } from 'react';

interface Props extends OverlayScrollbarsComponentProps {
  innerContainerClassName?: string;
  shiftScrollbarBy?: number;
}

const CatOverlayScrollbarsComponent = (props: Props, ref: React.Ref<any>) => {
  const { children, innerContainerClassName, shiftScrollbarBy, style, ...rest } = props;

  const renderComponent = () => {
    if (shiftScrollbarBy === undefined || shiftScrollbarBy === null) {
      return (
        <OverlayScrollbarsComponent ref={ref} style={style} {...rest}>
          {children}
        </OverlayScrollbarsComponent>
      );
    }

    const initialMargin = 6;
    const calculatedMargin = initialMargin + shiftScrollbarBy;
    const negativeMargin = -1 * calculatedMargin;

    return (
      <OverlayScrollbarsComponent
        ref={ref}
        style={{ ...style, marginLeft: negativeMargin, marginRight: negativeMargin }}
        {...rest}
      >
        <div
          className={innerContainerClassName ?? ''}
          style={{ marginLeft: calculatedMargin, marginRight: calculatedMargin }}
        >
          {children}
        </div>
      </OverlayScrollbarsComponent>
    );
  };

  return renderComponent();
};

export default forwardRef(CatOverlayScrollbarsComponent);
