import { AlertIcon } from '@borda/cat-icons';
import { AlertResponse, Priority, ResponseStatuses } from 'views/Alert/types';
import { CatTypography } from '@borda/cat-ui';
import { getHeaderIconColor } from './helpers';
import AlertStatusChip from 'views/Alert/Common/AlertStatusChip';
import AlertTimeChip from 'views/Alert/Common/AlertTimeChip';
import PriorityChip from 'views/Alert/Common/PriorityChip';

type Props = {
  alertTitle: string;
  createDate: string;
  lastResponse: AlertResponse;
  priority: Priority;
  status: ResponseStatuses;
};

function Header(props: Props) {
  const { alertTitle, createDate, lastResponse, priority, status } = props;

  const iconColor = getHeaderIconColor(status);

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <AlertIcon color={iconColor} contained hoverable={false} />
        <CatTypography className="font-bold">{alertTitle}</CatTypography>
      </div>
      <div className="flex flex-none gap-0.5 self-start">
        <PriorityChip chipSize="small" priority={priority} variant="outlined" />
        <AlertStatusChip chipSize="small" status={status} />
        <AlertTimeChip createDate={createDate} lastResponse={lastResponse} status={status} />
      </div>
    </div>
  );
}

export default Header;
