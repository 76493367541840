import { NotificationPaylod, useNotificationHandler } from 'utils/useNotificationHandler';
import { apiUrls } from 'api';
import { selectUseSocketForNotification } from 'store/slices/session';
import { useSignalR } from 'utils/signalR';
import { useTypedSelector } from 'hooks/useTypedSelector';

export const useInitializeNotificationSocket = () => {
  const useSocketForNotification = useTypedSelector(selectUseSocketForNotification);
  const { handleNotification } = useNotificationHandler();

  useSignalR<NotificationPaylod>(
    {
      connectionUrl: apiUrls.notification.connectNotificationSocket(),
      disabled: !useSocketForNotification,
      eventName: 'NotificationReceived'
    },
    {
      onDataReceived: (payload) => {
        const { body, data, title, webLink } = payload;
        handleNotification({ body, data, title, webLink });
      }
    }
  );
};
