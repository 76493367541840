import { combineReducers } from 'redux';
import breakdownFilterSlice from './filter/slice';
import breakdownSlice from './list/slice';

const breakdownReducer = combineReducers({
  breakdownList: breakdownSlice,
  breakdownListFilter: breakdownFilterSlice
});

export default breakdownReducer;
