import { ALERT_DIALOG } from 'routes/constant-route';
import { invalidateAlertQuery } from 'views/Alert/AlertPanel/queries';
import { showSnackbar } from './snackbar';
import { useCatNavigate } from '@borda/cat-core';
import { useMarkNotificationAsReadByIdMutation } from 'views/Notifications/queries';
import { useMatch } from 'react-router-dom';

export type NotificationPaylod = {
  body: string;
  data: Record<string, string>;
  title: string;
  webLink: string;
};

export const useNotificationHandler = () => {
  const navigate = useCatNavigate();
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadByIdMutation();

  const alertMatch = useMatch(ALERT_DIALOG);

  const handleAlertNotification = (payload: NotificationPaylod) => {
    const { body, data, title } = payload ?? {};
    const { AlertId, PopUpAlert } = data ?? {};

    invalidateAlertQuery();

    const alertUrl = ALERT_DIALOG.replace(':alertId', AlertId);

    const onClick = () => {
      if (AlertId) {
        navigate(alertUrl);
      }
    };

    showSnackbar({
      message: body,
      onClick,
      title,
      type: 'info'
    });

    if (PopUpAlert === 'True' && !alertMatch) {
      const alertButton = document.getElementById('alert-panel-btn');
      alertButton?.click();

      onClick();
    }
  };

  const handleStandardNotification = (payload: NotificationPaylod) => {
    const { body, data, title, webLink } = payload ?? {};
    const { notificationId } = data ?? {};

    const onClick = () => {
      const { pathname: urlPathname, search } = new URL(webLink);
      const targetUrl = urlPathname + search;

      if (notificationId) {
        markNotificationAsRead(notificationId);
      }
      navigate(targetUrl);
    };

    showSnackbar({
      message: body,
      onClick: webLink ? onClick : undefined,
      title,
      type: 'success'
    });
  };

  const handleNotification = async (payload: NotificationPaylod) => {
    const { data } = payload ?? {};

    if (data?.IsAlert === 'True') {
      handleAlertNotification(payload);
    } else {
      handleStandardNotification(payload);
    }
  };

  return { handleNotification };
};
