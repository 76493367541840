import { combineReducers } from 'redux';
import protectionGroupFilter from './group/filter/slice';
import protectionGroupSlice from './group/list/slice';
import protectionList from './protectionList/slice';
import protectionListFilterSlice from './protectionListFilter/protectionListFilter';

const protectionReducer = combineReducers({
  protectionGroupList: protectionGroupSlice,
  protectionGroupListFilter: protectionGroupFilter,
  protectionList,
  protectionListFilter: protectionListFilterSlice
});

export default protectionReducer;
