import { BREKADOWNLIST, getColumnsKey } from 'helpers/localStorage';
import { TaskItem } from '../../common/type';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export const listAdapter = createListAdapter<TaskItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(BREKADOWNLIST)
});

export const breakdownListSlice = createSlice({
  initialState: listAdapter.getInitialState({}),
  name: 'breakdownList',
  reducers: {
    ...listAdapter.getReducers()
  }
});

export const breakdownListActions = breakdownListSlice.actions;

export default breakdownListSlice.reducer;
