import { combineReducers } from '@reduxjs/toolkit';
import breakdownReducer from './breakdown';
import myRequestsListSlice from './myRequests/myRequests';
import protectionReducer from './protection';
import waitingForConfirmationListSlice from './waitingForConformation/waitingForConfirmationList';

const taskReducer = combineReducers({
  brekadown: breakdownReducer,
  myRequestsList: myRequestsListSlice,
  protections: protectionReducer,
  waitingForConfirmationList: waitingForConfirmationListSlice
});

export default taskReducer;
