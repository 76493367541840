import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { environmentType, getAppSettings, getConfigs, getFeatureEnvironment } from './settings';
import { isSafari } from 'react-device-detect';

let userManager: UserManager;

export const getUserManager = async () => {
  if (!userManager) {
    const { clientId, scope, ssoUrl } = getSsoConfig();
    const { useMockData } = getAppSettings();

    // Disable session monitor on safari
    // https://dev.azure.com/bordasw/Quattro/_workitems/edit/13123
    const monitorSession = !isSafari;

    const userManagerConfig: UserManagerSettings = {
      accessTokenExpiringNotificationTimeInSeconds: 60,
      authority: ssoUrl,
      client_id: clientId,
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession,
      post_logout_redirect_uri: `${window.location.origin}`,
      redirect_uri: `${window.location.origin}/auth/callback`,
      response_mode: 'query',
      response_type: 'code',
      scope,
      silent_redirect_uri: `${window.location.origin}/silent_renew.html`,
      staleStateAgeInSeconds: 300
      // code below uses in memory store but it requires signinsilent after normal signin flow
      // userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    if (useMockData) {
      userManager = new MockUserManager(userManagerConfig);
    } else {
      userManager = new UserManager(userManagerConfig);
    }
  }

  return userManager;
};

interface SsoConfig {
  clientId: string;
  scope: string;
  ssoUrl: string;
}

const featureSsoEnvironment = 'dev';
export const getSsoUrl = () => {
  const config = getConfigs();
  const { ssoUrl } = config;

  if (environmentType === 'regular') {
    return ssoUrl;
  }

  const featureEnvironment = getFeatureEnvironment();
  if (featureEnvironment) {
    const featureSsoUrl = ssoUrl.replace(featureSsoEnvironment, featureEnvironment);
    return featureSsoUrl;
  }

  return ssoUrl;
};

const getSsoConfig = (): SsoConfig => {
  const ssoUrl = getSsoUrl();
  return {
    clientId: 'quattro.asset.client.web',
    scope: 'openid profile quattro.gateway.assetclient',
    ssoUrl
  };
};

const mockUser: User = {
  access_token: '',
  expired: false,
  expires_in: 30000000,
  profile: {
    aud: '',
    branches: [],
    exp: 44,
    given_name: 'Bob',
    iat: 2,
    iss: '',
    last_name: 'Myer',
    mainCategories: [],
    name: 'Bob Myer',
    role: 'General Admin',
    settings: { languageCode: 'tr', locale: 'tr', timeZone: '+03:00' },
    sub: '',
    user_auth_id: 'iser',
    username: 'bob@bordatech.com'
  },
  scopes: ['hello'],
  session_state: '',
  state: { redirectUrl: '/' },
  toStorageString: () => '',
  token_type: 'Bearer'
};

class MockUserManager extends UserManager {
  // eslint-disable-next-line class-methods-use-this
  getUser(): Promise<User> {
    return Promise.resolve(mockUser);
  }

  // eslint-disable-next-line class-methods-use-this
  signinSilent(): Promise<User | null> {
    return Promise.resolve(mockUser);
  }

  // eslint-disable-next-line class-methods-use-this
  signinRedirectCallback(): Promise<User> {
    return Promise.resolve(mockUser);
  }

  // eslint-disable-next-line class-methods-use-this
  signoutRedirect(): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line class-methods-use-this
  signinRedirect(): Promise<void> {
    return Promise.resolve();
  }
}
