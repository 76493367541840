import { TimeUnitTextForm } from './types';
import { useTimeFormatter } from './useTimeFormatter';
import TimeChipBase from './TimeChipBase';

type Props = {
  chipSize?: 'small' | 'medium';
  endDate: string;
  startDate: string;
  timeUnitForm?: TimeUnitTextForm;
};

function TimeChipDuration(props: Props) {
  const { chipSize = 'medium', endDate, startDate, timeUnitForm = 'long' } = props;

  const { formatTimeDifference } = useTimeFormatter(timeUnitForm);

  const timeText = formatTimeDifference(new Date(startDate), new Date(endDate));

  return <TimeChipBase chipSize={chipSize} time={timeText} title={timeText} />;
}

export default TimeChipDuration;
