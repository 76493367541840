import { PROTECTIONGROUPLIST, getColumnsKey } from 'helpers/localStorage';
import { TaskItem } from '../../../common/type';
import { createListAdapter } from '@borda/cat-core';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumnDynamicData } from './data';

export const protectionListAdapter = createListAdapter<TaskItem>({
  initialDynamicListDefinitions: initialColumnDynamicData,
  localStorageKey: getColumnsKey(PROTECTIONGROUPLIST)
});

export const protectionGroupListSlice = createSlice({
  initialState: protectionListAdapter.getInitialState({}),
  name: 'protectionGroupList',
  reducers: {
    ...protectionListAdapter.getReducers()
  }
});

export const protectionGroupListActions = protectionGroupListSlice.actions;

export default protectionGroupListSlice.reducer;
