import {
  BaseTaskListFilter,
  BreakdownListFilter,
  DateFilterOptions,
  PersonFilterOptions,
  Priority,
  ProtectionGroupListFilter,
  StatusKeys,
  TaskCost,
  TaskType
} from './type';

export const initialCost: TaskCost = {
  amount: null,
  costType: null,
  costTypeId: null,
  currency: 'USD',
  explanation: '',
  id: null,
  parentCostType: null,
  parentCostTypeId: null
};

export const statusKeys: StatusKeys[] = ['open', 'inProgress', 'paused', 'closed'];
export const priorityKeys: Priority[] = ['none', 'low', 'medium', 'high'];
export const taskTypeKeys: TaskType[] = ['breakdown', 'calibration', 'maintenance', 'retirement'];
export const personFilterOption: PersonFilterOptions[] = ['me', 'allMyTeam', 'specificPersons'];

export const dateFilterOptions: DateFilterOptions[] = [
  'today',
  'tomorrow',
  'preceding',
  'specificDate'
];

const defaultCommonTaskListFilter: BaseTaskListFilter = {
  branchIds: [],
  dateFilters: [{ type: 'today' }, { type: 'tomorrow' }, { type: 'preceding' }],
  localStorageInitialized: false,
  personnelFilters: [
    {
      type: 'me'
    },
    {
      type: 'allMyTeam'
    }
  ],
  statuses: ['open', 'inProgress', 'paused'],
  taskTypes: []
};

export const defaultBreakdownListFilter: BreakdownListFilter = {
  ...defaultCommonTaskListFilter,
  priorities: []
};

export const defaultProtectionGroupListFilter: ProtectionGroupListFilter = {
  ...defaultCommonTaskListFilter
};
