import { APPSETTINGS, getSettingsKey } from 'helpers/localStorage';
import { Branch } from 'store/slices/branches';
import { TurboardConfiguration } from './turboard';
import { isArrayNullOrEmpty, loadFromLocalStorage, saveToLocalStorage } from '@borda/cat-core';
import { queryClient } from './react-query';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const settingQueryKeys = {
  all: () => ['settingQueryKeys'],
  configs: () => [...settingQueryKeys.all(), 'configs']
};

export type AppSettings = {
  enableDevelopmentFeatures: boolean;
  enableLiveTranslations: boolean;
  useMockData: boolean;
};

export type Environment = 'local' | 'dev' | 'test' | 'prod';

export type ProductKeys = 'asset' | 'patient';
export type ProductKeyEnabled = `${ProductKeys}Enabled`;

export const isUserAuthorizedOnProduct = (product: ProductKeys, branches: Branch[]) => {
  const propName = `${product}Enabled` as ProductKeyEnabled;
  return branches.some((branch) => branch && !!branch[propName]);
};

export const isUserAuthorizedOnProducts = (products: ProductKeys[], branches: Branch[]) => {
  if (isArrayNullOrEmpty(products)) {
    return true;
  }

  const isAuthorized = products.some((product) => isUserAuthorizedOnProduct(product, branches));

  return isAuthorized;
};

const environment: Environment = (window as any).dynamicEnv.NODE_DYNAMIC_ENVIRONMENT;
export const appVersion: string = (window as any).dynamicEnv.APP_VERSION;

type EnvironmentType = 'regular' | 'feature';
export const environmentType: EnvironmentType = (window as any).dynamicEnv.ENVIRONMENT_TYPE;

const featureEnvironments: Environment[] = ['dev', 'test'];

export const getFeatureEnvironment = (): Environment => {
  if (environmentType === 'feature') {
    for (let i = 0; i < featureEnvironments.length; i++) {
      const environment = featureEnvironments[i];
      if (window.location.hostname.includes(environment)) {
        return environment;
      }
    }
  }

  return null;
};

const initialSettings: AppSettings = {
  enableDevelopmentFeatures: environment === 'dev' || environment === 'local',
  enableLiveTranslations: false,
  useMockData: false
};

let settings: AppSettings = null;
const initSettings = () => {
  const fromLocalStorage = loadFromLocalStorage(getSettingsKey(APPSETTINGS));
  const localSettings: AppSettings = {
    ...initialSettings,
    ...JSON.parse(fromLocalStorage ?? null)
  };

  if (environment === 'prod') {
    localSettings.useMockData = false;
  }

  settings = {
    enableDevelopmentFeatures: localSettings.enableDevelopmentFeatures,
    enableLiveTranslations: localSettings.enableLiveTranslations,
    useMockData: localSettings.useMockData
  };

  saveToLocalStorage(getSettingsKey(APPSETTINGS), JSON.stringify(settings));
};

const initDevelopmentFeatures = (previewDevelopmentFeatures: boolean) => {
  const fromLocalStorage = loadFromLocalStorage(getSettingsKey(APPSETTINGS));
  const localSettings: AppSettings = {
    ...initialSettings,
    ...JSON.parse(fromLocalStorage ?? null)
  };

  if (!previewDevelopmentFeatures) {
    localSettings.enableDevelopmentFeatures = false;
    localSettings.enableLiveTranslations = false;
  }

  settings = {
    enableDevelopmentFeatures: localSettings.enableDevelopmentFeatures,
    enableLiveTranslations: localSettings.enableLiveTranslations,
    useMockData: localSettings.useMockData
  };

  saveToLocalStorage(getSettingsKey(APPSETTINGS), JSON.stringify(settings));
};

export const getAppSettings = () => {
  if (!settings) {
    initSettings();
  }

  return settings;
};

export const getDevelopmentFeaturesEnabled = () => getAppSettings().enableDevelopmentFeatures;

export const getEnvironment = () => environment;

export const setAppSettings = (newSettings: AppSettings) => {
  saveToLocalStorage(getSettingsKey(APPSETTINGS), JSON.stringify(newSettings));
};

export type Config = {
  common: {
    firebase: {
      apiKey: string;
      appId: string;
      authDomain: string;
      messagingSenderId: string;
      projectId: string;
      storageBucket: string;
      vapidKey: string;
    };
    frill: {
      enabled: boolean;
      token: string;
      url: string;
    };
    mixpanel: {
      token: string;
    };
    mui: {
      licenseKey: string;
    };
    previewDevelopmentFeatures: boolean;
    sentry: {
      dsn: string;
      enabled: boolean;
      environment: string;
      tracesSampleRate: number;
    };
    teamworkDesk: {
      enabled: boolean;
      token: string;
      url: string;
    };
    traduora: {
      clientId: string;
      projectId: string;
      secret: string;
    };
  };
  deployment: string;
  pointr: PointrConfig;
  reporting: TurboardConfiguration;
  ssoUrl: string;
};

export type PointrConfig = {
  apiUrl: string;
  clientInternalIdentifier: string;
  clientSecret: string;
  mapMinZoom: number;
};

export const getConfigFn = async (signal?: AbortSignal) => {
  const response = await axios.get<Config>('/config.json', { baseURL: '', signal });
  const { data } = response;

  initDevelopmentFeatures(data.common.previewDevelopmentFeatures);

  return data;
};

export const useConfigQuery = () => {
  const queryInfo = useQuery({
    gcTime: Infinity,
    queryFn: async ({ signal }) => getConfigFn(signal),
    queryKey: settingQueryKeys.configs(),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  return queryInfo;
};

export const fetchConfigIfNecessary = async () => {
  const config = getConfigs();
  if (config) {
    return config;
  }

  const newConfig = await getConfigFn();
  queryClient.setQueryData(settingQueryKeys.configs(), newConfig);

  return newConfig;
};

export const getConfigs = () => queryClient.getQueryData<Config>(settingQueryKeys.configs());
