import { TimeUnitTextForm } from './types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTimeFormatter } from './useTimeFormatter';
import { useVisibilityChange } from 'hooks/useVisibilityChange';
import TimeChipBase from './TimeChipBase';

type Props = {
  chipSize?: 'small' | 'medium';
  date: string;
  timeUnitForm?: TimeUnitTextForm;
};

function TimeChipElapsed(props: Props) {
  const { chipSize = 'medium', date, timeUnitForm = 'long' } = props;

  const { formatTimeDifference } = useTimeFormatter(timeUnitForm);
  const documentVisible = useVisibilityChange();

  const [timeText, setTimeText] = useState<string>('');

  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const clearTimers = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const getTimeText = useCallback((): string => {
    const now = new Date();
    const past = new Date(date);

    return formatTimeDifference(past, now);
  }, [date, formatTimeDifference]);

  const setupTimers = useCallback(() => {
    clearTimers();
    setTimeText(getTimeText());

    const now = new Date();
    const secondsUntilNextMinute = 60 - now.getSeconds();
    const waitMs = secondsUntilNextMinute * 1000;

    timeoutRef.current = setTimeout(() => {
      setTimeText(getTimeText());

      intervalRef.current = setInterval(() => {
        setTimeText(getTimeText());
      }, 60 * 1000);
    }, waitMs);
  }, [clearTimers, getTimeText]);

  useEffect(() => {
    if (documentVisible) {
      setupTimers();
    } else {
      clearTimers();
    }

    return () => {
      clearTimers();
    };
  }, [documentVisible, date, setupTimers, clearTimers]);

  return <TimeChipBase chipSize={chipSize} time={timeText} title={timeText} />;
}

export default TimeChipElapsed;
