import { AlertResponse, ResponseStatuses } from 'views/Alert/types';
import { AlertResponseIcon } from '@borda/cat-icons';
import { CatIconButton, CatTooltip, CatTypography, useLocalizationHelpers } from '@borda/cat-ui';
import { useCatTranslation } from 'utils/localization';

// TODO: Talk with Design team for overflow typographys

type Props = {
  isDisabled: boolean;
  lastResponse: AlertResponse;
  onClick: () => void;
  status: ResponseStatuses;
};

function AlertLastResponse(props: Props) {
  const { isDisabled, lastResponse, onClick, status } = props;

  const { t: tAlertCard } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.alert_card'
  });

  const { t: tAlertClosed } = useCatTranslation('translation', {
    keyPrefix: 'alert.alert_closed'
  });

  const { t: tAlertSubStatuses } = useCatTranslation('translation', {
    keyPrefix: 'alert.substatuses'
  });

  const { responseTime, subStatus, user } = lastResponse ?? {};

  const fullName = user ? `${user.firstName} ${user.lastName}` : '-';

  const { formatDate, formatTime } = useLocalizationHelpers();

  const lastResponseTime =
    responseTime && `${formatDate(responseTime)} ${formatTime(responseTime)}`;

  const hasAnyResponded = !!lastResponse;

  const getAlertResponseText = () => {
    if (status === 'closed') {
      const isResolved = subStatus === 'resolve';

      return isResolved ? tAlertClosed('resolved') : tAlertClosed('rejected');
    }

    return tAlertSubStatuses(subStatus);
  };

  const responseButtonTooltipText = isDisabled
    ? tAlertCard('response_button_tooltips.cannot_respond')
    : tAlertCard('response_button_tooltips.click_to_respond');

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1">
        <CatTypography className="opacity-80" variant="body2">
          {tAlertCard('last_response')}
        </CatTypography>
        {hasAnyResponded ? (
          <div className="flex max-w-80 items-center gap-1">
            <CatTypography
              className="truncate font-bold"
              title={getAlertResponseText()}
              variant="body2"
            >
              {getAlertResponseText()}
            </CatTypography>
            <span className="opacity-80">|</span>
            <CatTypography className="truncate opacity-80" title={fullName} variant="body2">
              {fullName}
            </CatTypography>
            <span className="opacity-80">|</span>
            <CatTypography className="truncate opacity-80" title={lastResponseTime} variant="body2">
              {lastResponseTime}
            </CatTypography>
          </div>
        ) : (
          <CatTypography className="font-bold" variant="body2">
            {tAlertCard('no_response')}
          </CatTypography>
        )}
      </div>
      <CatTooltip
        arrow
        placement="top-start"
        title={<CatTypography variant="body2">{responseButtonTooltipText}</CatTypography>}
      >
        <span>
          <CatIconButton disabled={isDisabled} onClick={onClick}>
            <AlertResponseIcon color="blue" />
          </CatIconButton>
        </span>
      </CatTooltip>
    </div>
  );
}

export default AlertLastResponse;
