import { AlertPanelFilter, ExtraFilter, extraFilters } from 'views/Alert/AlertPanel/types';
import {
  CatCheckbox,
  CatIconButton,
  CatMenu,
  CatMenuDivider,
  CatMenuItem,
  CatTooltip,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { FilterIcon } from '@borda/cat-icons';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

function ExtraFilters() {
  const { t: tAlertPanel } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.header.filters.extra_filters'
  });

  const popupState = usePopupState({ popupId: 'extraFilters', variant: 'popover' });

  const { setValue, watch } = useFormContext<AlertPanelFilter>();
  const { extraFilters: selectedExtraFilters } = watch();

  const [localExtraFilters, setLocalExtraFilters] = useState<ExtraFilter[]>(selectedExtraFilters);
  useEffect(() => {
    setLocalExtraFilters(selectedExtraFilters);
  }, [selectedExtraFilters]);

  const handleFilterClick = (extraFilter: ExtraFilter) => {
    setLocalExtraFilters((prevState: ExtraFilter[]) => {
      const newState: ExtraFilter[] = [...prevState];
      const index = newState.findIndex((i) => i === extraFilter);

      if (index === -1) {
        newState.push(extraFilter);
      } else {
        newState.splice(index, 1);
      }

      return newState;
    });
  };

  const isAnyExtraFilterSelected = selectedExtraFilters.length > 0;

  const handleClose = () => {
    popupState.close();

    const isChanged = localExtraFilters.length !== selectedExtraFilters.length;
    trackMixpanelEvent(
      isChanged ? 'AlertPanel_ExtraFiltersIsFiltered' : 'AlertPanel_ExtraFiltersIsClosed'
    );

    setValue('extraFilters', localExtraFilters, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const getTooltipText = () => {
    if (selectedExtraFilters.length === 0) {
      return tAlertPanel('filter_tooltips.default');
    }

    if (selectedExtraFilters.length === 1) {
      return tAlertPanel('filter_tooltips.single_select', {
        selectedFilter: tAlertPanel(`filter_types.${selectedExtraFilters[0]}`)
      });
    }

    return tAlertPanel('filter_tooltips.multiple_select', {
      filterCount: selectedExtraFilters.length
    });
  };

  return (
    <>
      <CatTooltip title={<CatTypography variant="body2">{getTooltipText()}</CatTypography>}>
        <CatIconButton {...bindTrigger(popupState)}>
          <div className="relative flex items-center">
            <FilterIcon color="blue" />
            {isAnyExtraFilterSelected && (
              <div className="bg-red absolute -right-1 -top-1 flex h-3 w-3 items-center justify-center rounded-full border border-white text-[8px] text-white">
                <CatTypography variant="inherit">
                  {selectedExtraFilters.length.toString()}
                </CatTypography>
              </div>
            )}
          </div>
        </CatIconButton>
      </CatTooltip>
      <CatMenu {...bindMenu(popupState)} addEmptyFirstItem onClose={handleClose} width="292px">
        <CatTypography className="mx-4 opacity-80" variant="body1">
          <CatTrans i18nKey="title" t={tAlertPanel} />
        </CatTypography>
        <CatMenuDivider />
        {extraFilters.map((extraFilter, index) => {
          const checked = localExtraFilters.includes(extraFilter);
          const isLastItem = index === extraFilters.length - 1;

          return (
            <div key={extraFilter}>
              <CatMenuItem onClick={() => handleFilterClick(extraFilter)}>
                <CatCheckbox checked={checked} id={extraFilter} paddingSize="none" />
                <CatTypography
                  className={clsx(checked ? 'font-bold' : '', 'truncate')}
                  variant="body2"
                >
                  <CatTrans i18nKey={`filter_options.${extraFilter}`} t={tAlertPanel} />
                </CatTypography>
              </CatMenuItem>
              {index % 2 === 1 && !isLastItem && <CatMenuDivider />}
            </div>
          );
        })}
      </CatMenu>
    </>
  );
}

export default ExtraFilters;
