import {
  AlertPanelFilter,
  AlertPanelSort as AlertPanelSortTypes,
  alertPanelSortings
} from '../../types';
import {
  CatIconButton,
  CatMenu,
  CatMenuDivider,
  CatMenuItem,
  CatRadio,
  CatTooltip,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { FilterListIcon } from '@borda/cat-icons';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { defaultAlertPanelFilter } from '../../data';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useController, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

function AlertPanelSort() {
  const { t: tAlertPanelSorts } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.header.sorts'
  });

  const popupState = usePopupState({ popupId: 'alertPanelSorts', variant: 'popover' });

  const { control } = useFormContext<AlertPanelFilter>();

  const {
    field: { onChange: onSortChange, value: selectedSort }
  } = useController({ control, name: 'sortBy' });

  const handleSortClick = (sort: AlertPanelSortTypes) => {
    trackMixpanelEvent('AlertPanel_AlertsAreSorted');
    onSortChange(sort);
    popupState.close();
  };

  const handleClose = () => {
    trackMixpanelEvent('AlertPanel_SortsMenuIsClosed');
    popupState.close();
  };

  const isSortButtonHovered = selectedSort && selectedSort !== defaultAlertPanelFilter.sortBy;

  return (
    <>
      <CatTooltip
        title={
          <CatTypography variant="body2">
            {tAlertPanelSorts(`sort_tooltips.${selectedSort}`)}
          </CatTypography>
        }
      >
        <CatIconButton {...bindTrigger(popupState)}>
          <div className="relative flex items-center">
            <FilterListIcon alwaysHovered={isSortButtonHovered} color="darkGrey" contained />
          </div>
        </CatIconButton>
      </CatTooltip>
      <CatMenu {...bindMenu(popupState)} addEmptyFirstItem onClose={handleClose} width="292px">
        <CatTypography className="mx-4 opacity-80" variant="body1">
          <CatTrans i18nKey="title" t={tAlertPanelSorts} />
        </CatTypography>
        <CatMenuDivider />
        {alertPanelSortings.map((sort, index) => {
          const checked = sort === selectedSort;
          const isLastItem = index === alertPanelSortings.length - 1;

          return (
            <div key={sort}>
              <CatMenuItem onClick={() => handleSortClick(sort)}>
                <CatRadio checked={checked} id={sort} name={sort} size="medium" zeroPadding />
                <CatTypography
                  className={clsx(checked ? 'font-bold' : '', 'truncate')}
                  variant="body2"
                >
                  <CatTrans i18nKey={`options.${sort}`} t={tAlertPanelSorts} />
                </CatTypography>
              </CatMenuItem>
              {index % 2 === 1 && !isLastItem && <CatMenuDivider />}
            </div>
          );
        })}
      </CatMenu>
    </>
  );
}

export default AlertPanelSort;
