import { TimeUnitTextForm } from './types';
import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useCatTranslation } from 'utils/localization';

export const useTimeFormatter = (timeUnitForm: TimeUnitTextForm = 'long') => {
  const { t: tTimeCommon } = useCatTranslation('translation', {
    keyPrefix: 'common.time'
  });

  const { t: tTime } = useCatTranslation('translation', {
    keyPrefix: `common.time.time_by_unit.${timeUnitForm}`
  });

  const formatTimeDifference = (startDate: Date, endDate: Date): string => {
    const hours = differenceInHours(endDate, startDate);
    const minutes = differenceInMinutes(endDate, startDate);
    const seconds = differenceInSeconds(endDate, startDate);

    if (seconds < 60) {
      return tTimeCommon('less_than', { value: tTime('minutes', { value: 1 }) });
    }

    if (minutes < 60) {
      return tTime('minutes', { value: minutes });
    }

    return `${tTime('hours', { value: hours })} ${tTime('minutes', { value: minutes % 60 })}`;
  };

  return { formatTimeDifference };
};
