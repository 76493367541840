const categoryUrls = {
  checkIsMainCategoryDeletable: (id: string) => `/api/category/main-categories/${id}/any-associate`,
  deleteCategories: () => '/api/category/categories',
  deleteMainCategory: (id: string) => `/api/category/main-categories/${id}`,
  getCategoryFullPath: (id: string) => `/api/category/categories/${id}/full-path`,
  getCategoryProtectionRequirement: (mainCategoryId: string, protectionType: string) =>
    `/api/category/categories/main-category/${mainCategoryId}/plan-type/${protectionType}`,
  getCategoryProtectionRequirementAuthorization: (mainCategoryId: string, protectionType: string) =>
    `/api/category/categories/main-category/${mainCategoryId}/plan-type/${protectionType}/authorization`,
  getMultipleCategoriesByIds: () => '/api/category/categories/basics',
  getParentCategoriesByIds: () => '/api/category/categories/parents',
  moveCategory: (id: string) => `/api/category/categories/${id}/move`,
  updateCategoryProtectionRequirement: (mainCategoryId: string, protectionType: string) =>
    `/api/category/categories/main-category/${mainCategoryId}/plan-type/${protectionType}`
};

const brandUrls = {
  addBrand: () => '/api/category/brands',
  addBrandToCategory: (categoryId: string) => `/api/category/categories/${categoryId}/brands`,
  getAllBrands: () => '/api/category/brands',
  getBrandsByCategoryId: (categoryId: string) => `/api/category/categories/${categoryId}/brands`,
  getBrandsByMainCategoryIds: () => '/api/category/main-categories/brands',
  removeBrandFromCategory: (categoryId: string, brandId: string) =>
    `/api/category/categories/${categoryId}/brands/${brandId}`
};

const modelUrls = {
  addModel: () => '/api/category/models',
  addModelToCategoryAndBrand: (categoryId: string, brandId: string) =>
    `/api/category/categories/${categoryId}/brands/${brandId}/models`,
  deleteModelImage: (modelId: string) => `/api/category/models/${modelId}/photo`,
  getModelPhotoByPhotoPath: (photoPath: string) => `/api/category/${photoPath}`,
  getModelsByBrandId: (brandId: string) => `/api/category/brands/${brandId}/models`,
  getModelsByBrandIds: () => '/api/category/brands/models',
  getModelsByCategoryIdAndBrandId: (categoryId: string, brandId: string) =>
    `/api/category/categories/${categoryId}/brands/${brandId}/models`,
  getModelsByMainCategoryIdAndBrandId: (mainCategoryId: string, brandId: string) =>
    `/api/category/main-categories/${mainCategoryId}/brands/${brandId}/models`,
  removeModelFromCategoryAndBrand: (categoryId: string, brandId: string, modelId: string) =>
    `/api/category/categories/${categoryId}/brands/${brandId}/models/${modelId}`,
  updateModelImage: (modelId: string) => `/api/category/models/${modelId}/photo`
};

const categoryTaskUrls = {
  addCategoryTask: () => '/api/category/tasks',
  approveCategoryTask: (id: string) => `/api/category/tasks/${id}/approve`,
  checkExistingCategoryRelation: (id: string) => `/api/category/tasks/${id}/check`,
  filterCategoryTasks: () => '/api/category/tasks/filter',
  getCategoryTaskByBrandAndModel: (mainCategoryId: string, brandId: string, modelId: string) =>
    `/api/category/tasks/mainCategory/${mainCategoryId}/brand/${brandId}/model/${modelId}`,
  getCategoryTaskById: (categoryTaskId: string) => `/api/category/tasks/${categoryTaskId}`,
  getCategoryTaskStatusCounts: () => '/api/category/tasks/filter/count',
  rejectCategoryTask: (id: string) => `/api/category/tasks/${id}/reject`,
  updateCategoryTask: (categoryBrandModelId: string) =>
    `/api/category/tasks/${categoryBrandModelId}`
};

export default { ...brandUrls, ...categoryUrls, ...categoryTaskUrls, ...modelUrls };
