import { AlertResponse, ResponseStatuses } from '../types';
import React from 'react';
import TimeChipDuration from 'views/Common/Time/TimeChipDuration';
import TimeChipElapsed from 'views/Common/Time/TimeChipElapsed';

type Props = {
  createDate: string;
  lastResponse: AlertResponse;
  status: ResponseStatuses;
};

function AlertTimeChip(props: Props) {
  const { createDate, lastResponse, status } = props;

  const isAlertClosed = status === 'closed';

  if (isAlertClosed) {
    return (
      <TimeChipDuration
        chipSize="small"
        endDate={lastResponse.responseTime}
        startDate={createDate}
        timeUnitForm="short"
      />
    );
  }

  return <TimeChipElapsed chipSize="small" date={createDate} timeUnitForm="short" />;
}

export default AlertTimeChip;
