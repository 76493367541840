import { DynamicListDefinition } from '@borda/cat-core';
import { TaskItem } from 'store/slices/tasks/common/type';

export const initialColumnDynamicData: DynamicListDefinition<TaskItem> = {
  columns: [
    {
      column: 'title',
      visible: true,
      width: 150
    },
    {
      column: 'statuses',
      visible: true,
      width: 150
    },
    {
      column: 'requestedDate',
      visible: true,
      width: 150
    },
    {
      column: 'responsiblePersonnel',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'requestedDate', direction: 'asc' }]
};
