import { ActivityIcon, TriggerIcon } from '@borda/cat-icons';
import { AlertLog } from 'views/Alert/types';
import { CatTypography, useLocalizationHelpers } from '@borda/cat-ui';
import { useCatTranslation } from 'utils/localization';
import AlertCardLocation from '../../Common/AlertCardLocation';

function HistoryItem(props: AlertLog) {
  const { location, logTime, logType, message } = props;

  const { t: tAlertCard } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.alert_card'
  });

  const { formatDate, formatTime } = useLocalizationHelpers();

  const isAlertStarted = logType === 'alertStarted';

  const IconComponent = isAlertStarted ? TriggerIcon : ActivityIcon;
  const title = isAlertStarted ? tAlertCard('alert_started') : tAlertCard('last_activity');

  const locationFullPath = location ? location.fullPath.slice().join(' / ') : '-';
  const lastSeenTime = logTime ? `${formatDate(logTime)} ${formatTime(logTime)}` : '-';

  return (
    <div className="flex gap-1">
      <div className="shadow-2 flex self-start rounded-full border-2 border-white">
        <IconComponent alwaysHovered color="darkGrey" contained fontSize="small" />
      </div>
      <div className="flex grow flex-col gap-1 pt-[2.5px]">
        <div className="flex items-center gap-0.5">
          <CatTypography className="font-semibold" variant="body2">
            {title}
          </CatTypography>
          <CatTypography variant="body2">{message}</CatTypography>
        </div>
        <div className="flex">
          <AlertCardLocation
            lastSeenTime={lastSeenTime}
            locationFullPath={locationFullPath}
            locationVariant="alertLog"
          />
        </div>
      </div>
    </div>
  );
}

export default HistoryItem;
