import { AlertIcon } from '@borda/cat-icons';
import { CatIconButton, CatTypography } from '@borda/cat-ui';
import { CircularProgress } from '@borda/cat-mui';
import { MutableRefObject } from 'react';
import { defaultAlertPanelFilter } from '../data';
import { useInfiniteAlertListQuery } from '../queries';
import { usePopupState } from 'material-ui-popup-state/hooks';
import AlertPanel from '../AlertPanel';

interface Props {
  popoverAnchorRef: MutableRefObject<HTMLDivElement>;
}

function AlertPanelButton(props: Props) {
  const { popoverAnchorRef } = props;

  const popupState = usePopupState({ popupId: 'alert-panel', variant: 'popover' });

  const handleAlertButtonClick = () => {
    popupState.open(popoverAnchorRef.current);
  };

  const { data, isFetching, isSuccess } = useInfiniteAlertListQuery({
    ...defaultAlertPanelFilter,
    statuses: ['open']
  });

  const totalOpenAlerts = isSuccess && data.pages[0].total;

  return (
    <>
      <CatIconButton id="alert-panel-btn" onClick={handleAlertButtonClick}>
        <div className="relative flex items-center">
          <AlertIcon alwaysHovered={popupState.isOpen} className="h-10 w-10" color="red" />
          {isFetching ? (
            <CircularProgress
              className="bg-red/30 absolute -right-1 -top-1 h-4 w-4 rounded-full"
              color="error"
              size={15}
            />
          ) : (
            totalOpenAlerts > 0 && (
              <div className="bg-red absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full border border-white text-[8px] text-white">
                <CatTypography variant="inherit">{totalOpenAlerts.toString()}</CatTypography>
              </div>
            )
          )}
        </div>
      </CatIconButton>
      <AlertPanel open={popupState.isOpen} popupState={popupState} />
    </>
  );
}

export default AlertPanelButton;
