import { DateFilter, PersonFilter, Priority, StatusKeys, TaskType } from '../../common/type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultBreakdownListFilter } from '../../common/data';

const breakdownFilterSlice = createSlice({
  initialState: defaultBreakdownListFilter,
  name: 'breakdownListFilter',
  reducers: {
    clearFilters: (draft) => {
      draft.branchIds = defaultBreakdownListFilter.branchIds;
      draft.personnelFilters = defaultBreakdownListFilter.personnelFilters;
      draft.priorities = defaultBreakdownListFilter.priorities;
      draft.statuses = defaultBreakdownListFilter.statuses;
      draft.taskTypes = defaultBreakdownListFilter.taskTypes;
      draft.dateFilters = defaultBreakdownListFilter.dateFilters;
    },
    datesFilterUpdated: (draft, action: PayloadAction<DateFilter[]>) => {
      draft.dateFilters = action.payload;
    },
    initFromLocalStorage: (draft, action) => {
      draft.branchIds = action.payload.branchIds;
      draft.personnelFilters = action.payload.personnel;
      draft.priorities = action.payload.priorities;
      draft.statuses = action.payload.statuses;
      draft.taskTypes = action.payload.taskTypes;
      draft.dateFilters = action.payload.dateFilters;
      draft.localStorageInitialized = true;
    },
    resetLocalStorageStatus: (draft) => {
      draft.localStorageInitialized = false;
    },
    taskTypesFilterUpdated: (draft, action: PayloadAction<TaskType[]>) => {
      draft.taskTypes = action.payload;
    },
    updateBranchFilters: (draft, action: PayloadAction<string[]>) => {
      draft.branchIds = action.payload;
    },
    updatePersonFilter: (draft, action: PayloadAction<PersonFilter[]>) => {
      draft.personnelFilters = action.payload;
    },
    updatePriority: (draft, action: PayloadAction<Priority[]>) => {
      draft.priorities = action.payload;
    },
    updateStatuses: (draft, action: PayloadAction<StatusKeys[]>) => {
      draft.statuses = action.payload;
    }
  }
});

export default breakdownFilterSlice.reducer;
export const {
  clearFilters,
  datesFilterUpdated,
  initFromLocalStorage,
  resetLocalStorageStatus,
  taskTypesFilterUpdated,
  updateBranchFilters,
  updatePersonFilter,
  updatePriority,
  updateStatuses
} = breakdownFilterSlice.actions;
