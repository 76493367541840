import { apiUrls } from 'api';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useGetImage = (imagePath: string) => {
  const queryInfo = useQuery({
    enabled: !!imagePath,
    queryFn: async ({ signal }) => {
      const response = await axios.get<Blob>(apiUrls.category.getModelPhotoByPhotoPath(imagePath), {
        responseType: 'blob',
        signal
      });

      const imageUrl = URL.createObjectURL(response.data);

      return imageUrl;
    },
    queryKey: getImageQueryKey(imagePath)
  });

  return queryInfo;
};

export const getImageQueryKey = (imagePath: string) => ['photos', imagePath];
