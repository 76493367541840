import {
  AssetFilter,
  AssetFilterWithExtraFilters,
  DefinitionPlanTypes
} from 'store/slices/asset/asset/filter/types';
import { AssetHistoryKeys } from 'store/slices/asset/asset/detail/types';
import { AssetListItem } from 'store/slices/asset/asset/list/types';
import { AssetStatus } from 'store/slices/common/types';
import { ColumnSort } from '@borda/cat-core';
import { TaskType } from 'store/slices/tasks/common/type';

export const assets = {
  actionButtonValidations: {
    all: () => [...assets.all(), 'actionButtonValidations'],
    byFilter: (
      allChecked: boolean,
      excludedIds: string[],
      includedIds: string[],
      filter: AssetFilterWithExtraFilters
    ) => [...assets.actionButtonValidations.all(), allChecked, excludedIds, includedIds, filter]
  },
  all: () => ['assets'],
  attributes: {
    all: () => [...assets.all(), 'attributes'],
    columns: () => [...assets.attributes.all(), 'columns']
  },
  brands: () => [...assets.all(), 'brands'],
  bulkEdit: {
    all: () => [...assets.all(), 'bulkEdit'],
    fieldCounts: {
      all: () => [...assets.bulkEdit.all(), 'fieldCounts'],
      byFilter: (
        allChecked: boolean,
        excludedIds: string[],
        includedIds: string[],
        filter: AssetFilterWithExtraFilters
      ) => [...assets.bulkEdit.fieldCounts.all(), allChecked, excludedIds, includedIds, filter]
    },
    selectedAssetsFromURL: {
      all: () => [...assets.bulkEdit.all(), 'selectedAssetsFromURL'],
      byHash: (hash: string) => [...assets.bulkEdit.selectedAssetsFromURL.all(), hash]
    }
  },
  custodyAssignment: {
    all: () => [...assets.all(), 'custodyAssignment'],
    count: () => [...assets.custodyAssignment.all(), 'count'],
    custodyAssignmentRequests: () => [...assets.custodyAssignment.all(), 'requests']
  },
  detail: {
    all: () => [...assets.all(), 'detail'],
    assetHistory: {
      all: () => [...assets.detail.all(), 'assetHistory'],
      byFilter: (assetId: string, page: number, types: AssetHistoryKeys[]) => [
        ...assets.detail.assetHistory.all(),
        assetId,
        page,
        types
      ]
    },
    byId: (userId: string) => [...assets.detail.all(), userId],
    taskHistory: {
      all: () => [...assets.detail.all(), 'taskHistory'],
      breakdown: (assetId: string, page: number) => [
        ...assets.detail.taskHistory.all(),
        assetId,
        page
      ],
      protection: (assetId: string, page: number, types: TaskType) => [
        ...assets.detail.taskHistory.all(),
        assetId,
        page,
        types
      ]
    }
  },
  dynamicColumns: {
    all: () => [...assets.all(), 'dynamicColumns'],
    byMainCategoryId: (mainCategoryId: string) => [...assets.dynamicColumns.all(), mainCategoryId]
  },
  export: {
    all: () => [...assets.all(), 'export'],
    detailByUrl: (operationUrl: string) => [...assets.export.all(), 'detailByUrl', operationUrl],
    fileById: (operationId: string) => [...assets.export.all(), 'fileById', operationId]
  },
  filter: {
    all: () => [...assets.all(), 'filter'],
    assetCodes: (branchIds: string[], searchText: string) => [
      ...assets.filter.all(),
      'assetCodes',
      branchIds,
      searchText
    ],
    assetPurchasedFirms: () => [...assets.filter.all(), 'assetPurchasedFirms'],
    contactContacts: () => [...assets.filter.all(), 'contactContacts'],
    contactFirms: () => [...assets.filter.all(), 'contactFirms'],
    planTitles: (planType: DefinitionPlanTypes) => [...assets.filter.all(), 'planTitles', planType],
    savedFilters: () => [...assets.filter.all(), 'savedFilters']
  },
  list: {
    all: () => [...assets.all(), 'list'],
    byFilter: (
      activeFilter: AssetFilter,
      extraFilters: AssetStatus[],
      sorts: ColumnSort<AssetListItem>[],
      customColumnNames: string[],
      commonColumnNames: string[]
    ) => [
      ...assets.list.all(),
      activeFilter,
      extraFilters,
      sorts,
      customColumnNames,
      commonColumnNames
    ]
  },
  locationSignalError: (
    isMyAsset: boolean,
    activeFilter: AssetFilter,
    extraFilters: AssetStatus[]
  ) => [...assets.all(), 'locationSignalError', isMyAsset, activeFilter, extraFilters],
  models: (brandId: string, mainCategoryId: string) => [
    ...assets.all(),
    'models',
    brandId,
    mainCategoryId
  ],
  myAssets: {
    all: () => [...assets.all(), 'myAssets'],
    byFilter: (
      extraFilters: AssetStatus[],
      customColumnNames: string[],
      commonColumnNames: string[]
    ) => [...assets.myAssets.all(), extraFilters, customColumnNames, commonColumnNames]
  },
  passiveNotification: {
    all: () => [...assets.all(), 'passiveNotification'],
    assetsByIds: (assetIds: string[]) => [
      ...assets.passiveNotification.all(),
      'assetsByIds',
      assetIds
    ],
    byId: (notificationId: string) => [...assets.passiveNotification.all(), notificationId]
  },
  planAssociation: {
    all: () => [...assets.all(), 'planAssociation'],
    associationInformation: (planId: string, assetIds: string[]) => [
      ...assets.planAssociation.all(),
      planId,
      assetIds
    ]
  }
};

export const assetManagementKeys = { assets };
