import { DateFilter, PersonFilter, StatusKeys, TaskType } from '../../../common/type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultProtectionGroupListFilter } from 'store/slices/tasks/common/data';

const protectionGroupListFilterSlice = createSlice({
  initialState: defaultProtectionGroupListFilter,
  name: 'protectionGroupListFilter',
  reducers: {
    clearFilters: (draft) => {
      draft.branchIds = defaultProtectionGroupListFilter.branchIds;
      draft.personnelFilters = defaultProtectionGroupListFilter.personnelFilters;
      draft.statuses = defaultProtectionGroupListFilter.statuses;
      draft.taskTypes = defaultProtectionGroupListFilter.taskTypes;
      draft.dateFilters = defaultProtectionGroupListFilter.dateFilters;
    },
    datesFilterUpdated: (draft, action: PayloadAction<DateFilter[]>) => {
      draft.dateFilters = action.payload;
    },
    initFromLocalStorage: (draft, action) => {
      draft.branchIds = action.payload.branchIds;
      draft.personnelFilters = action.payload.personnel;
      draft.statuses = action.payload.statuses;
      draft.taskTypes = action.payload.taskTypes;
      draft.dateFilters = action.payload.dateFilters;
      draft.localStorageInitialized = true;
    },
    resetLocalStorageStatus: (draft) => {
      draft.localStorageInitialized = false;
    },
    taskTypesFilterUpdated: (draft, action: PayloadAction<TaskType[]>) => {
      draft.taskTypes = action.payload;
    },
    updateBranchFilters: (draft, action: PayloadAction<string[]>) => {
      draft.branchIds = action.payload;
    },
    updatePersonFilter: (draft, action: PayloadAction<PersonFilter[]>) => {
      draft.personnelFilters = action.payload;
    },
    updateStatuses: (draft, action: PayloadAction<StatusKeys[]>) => {
      draft.statuses = action.payload;
    }
  }
});

export default protectionGroupListFilterSlice.reducer;
export const {
  clearFilters,
  datesFilterUpdated,
  initFromLocalStorage,
  resetLocalStorageStatus,
  taskTypesFilterUpdated,
  updateBranchFilters,
  updatePersonFilter,
  updateStatuses
} = protectionGroupListFilterSlice.actions;
